import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth, SignInButton } from '@clerk/clerk-react';
import Pricing from '../components/Pricing';
import styles from '../styles/Home.module.css';

const PricingPage = () => {
  const { isSignedIn } = useAuth();
  const navigate = useNavigate();

  const handleTryItFree = () => {
    if (isSignedIn) {
      navigate('/dashboard');
    }
    // If not signed in, the SignInButton component will handle opening the sign-in modal
  };

  return (
    <div className={styles.home}>
      <header className={styles.hero}>
        <div className={styles.container}>
          <h1 className={styles.title}>Flexible Pricing Plans for Every Business</h1>
          <p className={styles.subtitle}>Choose a plan that fits your needs and scale your e-commerce business.</p>
          <div className={styles.ctaButtons}>
            {isSignedIn ? (
              <button onClick={() => navigate('/dashboard')} className={styles.btnPrimary}>Go to Dashboard</button>
            ) : (
              <SignInButton mode="modal">
                <button onClick={handleTryItFree} className={styles.btnPrimary}>Try it for Free</button>
              </SignInButton>
            )}
          </div>
        </div>
      </header>

      <section className={styles.pricingSection}>
        <div className={styles.container}>
          <Pricing />
        </div>
      </section>
    </div>
  );
};

export default PricingPage;
